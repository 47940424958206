/* import __COLOCATED_TEMPLATE__ from './status-cell.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import HasFeatureHelper from '../../../helpers/has-feature';

const STATUSES = [
  {
    text: 'active',
    key: 'active',
  },
  {
    text: 'away',
    key: 'away',
  },
  {
    text: 'away-and-reassigning',
    key: 'reassign',
  },
];

export default class StatusCell extends Component {
  @service intercomEventService;
  @service appService;
  @service permissionsService;
  @service intl;
  @service store;

  // @ts-ignore
  hasFeatureHelper = HasFeatureHelper.create(this);

  get canEditAwayStatusReasons() {
    return this.hasFeatureHelper.compute(['hsg-tickets-away-mode-agent-status-custom-reasons']);
  }

  get canUseMandatoryAwayReasons() {
    return this.hasFeatureHelper.compute(['hsg-tickets-away-mode-mandatory-reasons']);
  }

  get canUpdateStatus() {
    return this.permissionsService.currentAdminCan('can_teammates__away_mode__change');
  }

  get awayStatusReasons() {
    return this.store.findAll('away-status-reason');
  }

  get admin() {
    return this.args.row.teammate;
  }

  get currentStatusKey() {
    if (this.admin.away_mode_enabled) {
      return this.admin.reassign_conversations ? 'reassign' : 'away';
    }
    return 'active';
  }

  get currentStatusReason() {
    return this.admin?.away_status_reason;
  }

  get currentStatusReasonId() {
    return this.admin?.away_status_reason_id;
  }

  get currentStatusText() {
    let currentStatus = STATUSES.find((status) => status.key === this.currentStatusKey);
    return this.intl.t(`table-cells.teammate-list.status-cell.${currentStatus.text}`);
  }

  get currentCustomReason() {
    return this.currentStatusReasonId
      ? this.store.peekRecord('away-status-reason', this.currentStatusReasonId)
      : null;
  }

  get groupList() {
    let options = STATUSES.reject((status) => {
      return (
        status.key === 'reassign' &&
        this.appService.app.requiresInboxSeatAccess &&
        !this.admin.hasInboxAccess
      );
    }).map((status) => {
      return {
        text: this.intl.t(`table-cells.teammate-list.status-cell.${status.text}`),
        value: status.key,
        isSelected: this.currentStatusKey === status.key,
      };
    });
    return [
      {
        hasItemsMarkableAsSelected: true,
        items: options,
      },
    ];
  }

  @action
  setActive() {
    this.admin.set('away_mode_enabled', false);
    this.admin.set('reassign_conversations', false);
    this.admin.set('away_status_reason', null);
    this.admin.set('away_status_reason_id', null);
    this.save();
  }

  @action
  setAwayWithStatus(status = null, isReassigning = false) {
    if (isReassigning) {
      this.setAwayAndReassigning(status);
    } else {
      this.setAway(status);
    }
  }

  setAway(status = null) {
    this.admin.set('away_mode_enabled', true);
    this.admin.set('reassign_conversations', false);
    if (status) {
      this.admin.set('away_status_reason', status.label);
      this.admin.set('away_status_reason_id', status.id);
    } else {
      this.admin.set('away_status_reason', null);
      this.admin.set('away_status_reason_id', null);
    }
    this.save();
  }

  setAwayAndReassigning(status = null) {
    this.admin.set('away_mode_enabled', true);
    this.admin.set('reassign_conversations', true);
    if (status) {
      this.admin.set('away_status_reason', status.label);
      this.admin.set('away_status_reason_id', status.id);
    } else {
      this.admin.set('away_status_reason', null);
      this.admin.set('away_status_reason_id', null);
    }
    this.save();
  }

  save() {
    this.intercomEventService.trackAnalyticsEvent({
      action: this.admin.reassign_conversations ? 'enabled' : 'disabled',
      object: 'reassign_conversation_setting',
      context: 'from_teammate_settings',
      models: [this.admin],
    });
    this.admin.saveOutOfOfficeSettings();
  }

  @action
  changeStatus(key) {
    let actions = {
      active: this.setActive,
      away: this.setAway,
      reassign: this.setAwayAndReassigning,
    };
    actions[key] && actions[key].call(this);
  }
}
